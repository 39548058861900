function GlideSlideshow(el, options) {
  this.el = el;
  this.options = options || {
    type: 'carousel',
    autoplay: false,
    animationDuration: 800,
    keyboard: true,
    hoverpause: false,
    gap: 0,
    swipeThreshold: 30
  };

  this.glideEl = this.el.querySelector('[data-glide-slideshow]');

  this.glide = undefined;

  this.countSlides = this.glideEl.querySelectorAll('.glide__slide').length;

  this._init();
}

GlideSlideshow.prototype._init = function() {
  this.glide = new Glide(this.glideEl, this.options);

  //update active caption
  if(this.el.querySelectorAll('[data-glide-caption]').length > 0)
    this.el.querySelectorAll('[data-glide-caption]')[0].classList.add('active');

  this.glide.on('run', function(move) {
    //update active caption
    if(this.el.querySelectorAll('[data-glide-caption]').length > 0) {
      this.el.querySelectorAll('[data-glide-caption]').forEach(function(el) {
        el.classList.remove('active');
      });
      this.el.querySelectorAll('[data-glide-caption]')[this.glide.index].classList.add('active');
    }

    //update counter
    if(this.el.querySelector('[data-glide-counter]'))
      this.el.querySelector('[data-glide-counter] [data-glide-current]').innerHTML = (this.glide.index +1);
  }.bind(this));

  //update counter
  if(this.el.querySelector('[data-glide-counter]')) {
    this.el.querySelector('[data-glide-counter] [data-glide-current]').innerHTML = 1;
    this.el.querySelector('[data-glide-counter] [data-glide-total]').innerHTML = this.countSlides;
  }

  if(this.countSlides > 1) {
    //init/mount slide
    this.glide.mount();
  }
};

GlideSlideshow.prototype.destroy = function() {
  this.glide.destroy();
};
